<template>
  <div class="exchange-rate-container">
    <div
      class="global-border-radius global-border-shadow exchange-rate-container-half-width"
    >
      <b-field grouped>
        <b-field label="Cantidad" label-position="inside" expanded>
          <b-input
            type="number"
            step="any"
            placeholder="Ejemplo: 100"
            expanded
            v-model="newConversion.amount"
            icon="counter"
            @blur="() => newConversionInput()"
          />
        </b-field>

        <b-field label="Divisa base" label-position="inside" expanded>
          <b-select
            placeholder="Ejemplo: MXN"
            expanded
            v-model="newConversion.from"
            icon="flag-outline"
            @input="newConversionInput"
          >
            <option value="MXN">MXN 🇲🇽</option>
            <option value="USD">USD 🇺🇸</option>
            <option value="EUR">EUR 🇪🇺</option>
            <option value="GBP">GBP 🇬🇧</option>
            <option value="CHF">CHF 🇨🇭</option>
            <option value="JPY">JPY 🇯🇵</option>
            <option value="HKD">HKD 🇭🇰</option>
            <option value="CNY">CNY 🇨🇳</option>
            <option value="AUD">AUD 🇦🇺</option>
            <option value="RUB">RUB 🇷🇺</option>
            <option value="AED">AED 🇦🇪</option>
            <option value="COP">COP 🇨🇴</option>
            <option value="CAD">CAD 🇨🇦</option>
            <option value="CLP">CLP 🇨🇱</option>
          </b-select>
        </b-field>
      </b-field>
    </div>
    <b-icon icon="swap-horizontal" size="is-medium" type="is-primary"> </b-icon>
    <div
      class="global-border-radius global-border-shadow exchange-rate-container-half-width"
    >
      <b-field grouped>
        <b-field label="Divisa objetivo" label-position="inside" expanded>
          <b-select
            placeholder="Ejemplo: USD"
            expanded
            v-model="newConversion.to"
            icon="flag"
            :disabled="!newConversion.amount"
            @input="newConversionInput"
          >
            <option value="MXN">MXN 🇲🇽</option>
            <option value="USD">USD 🇺🇸</option>
            <option value="EUR">EUR 🇪🇺</option>
            <option value="GBP">GBP 🇬🇧</option>
            <option value="CHF">CHF 🇨🇭</option>
            <option value="JPY">JPY 🇯🇵</option>
            <option value="HKD">HKD 🇭🇰</option>
            <option value="CNY">CNY 🇨🇳</option>
            <option value="AUD">AUD 🇦🇺</option>
            <option value="RUB">RUB 🇷🇺</option>
            <option value="AED">AED 🇦🇪</option>
            <option value="COP">COP 🇨🇴</option>
            <option value="CAD">CAD 🇨🇦</option>
            <option value="CLP">CLP 🇨🇱</option>
          </b-select>
        </b-field>
        <p v-if="result" class="exchange-rate-result">
          = {{ result | money("MXN", 2) }}
        </p>
      </b-field>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExchangeRate",
  components: {},
  props: [],
  data() {
    return {
      newConversion: {
        from: "USD",
        to: "MXN",
        amount: 1,
      },
      result: 0,
    };
  },
  mounted() {
    this.newConversionInput();
  },
  methods: {
    async newConversionInput() {
      if (
        this.newConversion.from &&
        this.newConversion.to &&
        this.newConversion.amount
      ) {
        const result = await this.$store.dispatch(
          "CONVERTRATE",
          this.newConversion
        );
        if (result.success) {
          this.result = result.result;
        }
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
@import "../../../Global/style/Global.css";
@import "../../../Global/style/InformationCards.css";
</style>
