<template>
  <div class="exchange-rate-container">
    <div
      class="global-border-radius global-border-shadow exchange-rate-container-half-width"
    >
      <b-field grouped>
        <b-field label="Cantidad" label-position="inside" expanded>
          <b-input
            type="number"
            step="any"
            placeholder="Ejemplo: 100"
            expanded
            v-model="newConversion.quantity"
            icon="counter"
            @blur="() => newUnitConversion()"
          />
        </b-field>

        <b-field label="Unidad base" label-position="inside" expanded>
          <b-select
            placeholder="Ejemplo: MXN"
            expanded
            v-model="newConversion.baseUnit"
            icon="scale-balance"
            @input="
              () => {
                getAvailableUnits();
              }
            "
          >
            <option value="" disabled>Seleccione una unidad</option>
            <option
              v-for="measure in availableBaseUnits"
              :value="measure"
              :key="measure"
            >
              {{ measure }}
            </option>
          </b-select>
        </b-field>
      </b-field>
    </div>
    <b-icon icon="swap-horizontal" size="is-medium" type="is-primary"> </b-icon>
    <div
      class="global-border-radius global-border-shadow exchange-rate-container-half-width"
    >
      <b-field grouped>
        <b-field label="Unidad objetivo" label-position="inside" expanded>
          <b-select
            placeholder="Ejemplo: kg"
            expanded
            v-model="newConversion.targetUnit"
            icon="weight-kilogram"
            :disabled="!newConversion.quantity"
            @input="newUnitConversion"
          >
            <option value="">Seleccione una unidad</option>
            <option
              v-for="measure in availableTargetUnits"
              :value="measure"
              :key="measure"
            >
              {{ measure }}
            </option>
          </b-select>
        </b-field>
        <p v-if="result" class="exchange-rate-result">= {{ result }}</p>
      </b-field>
    </div>
  </div>
</template>

<script>
export default {
  name: "UnitConversion",
  components: {},
  props: [],
  data() {
    return {
      availableBaseUnits: [],
      availableTargetUnits: [],
      newConversion: {
        baseUnit: "g",
        targetUnit: "kg",
        quantity: 1,
      },
      result: 0,
    };
  },
  mounted() {
    this.getAllAvailableUnits();
    this.getAvailableUnits();
    this.newUnitConversion();
  },
  methods: {
    async newUnitConversion() {
      if (
        this.newConversion.baseUnit &&
        this.newConversion.targetUnit &&
        this.newConversion.targetUnit
      ) {
        const result = await this.$store.dispatch(
          "CONVERTUNIT",
          this.newConversion
        );
        if (result !== undefined && result !== null) {
          this.result = `${result} ${this.newConversion.targetUnit}`;
        }
      }
    },
    async getAllAvailableUnits() {
      const result = await this.$store.dispatch("GETALLAVAILABLEUNITS");
      if (result.length > 0) {
        this.availableBaseUnits = result.sort();
      }
    },
    async getAvailableUnits() {
      this.newConversion.targetUnit = "";
      this.result = 0;
      const result = await this.$store.dispatch(
        "GETAVAILABLEUNITS",
        this.newConversion
      );
      if (result.length > 0) {
        this.availableTargetUnits = result.sort();
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
@import "../../../Global/style/Global.css";
@import "../../../Global/style/InformationCards.css";
</style>
