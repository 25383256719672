<template>
  <div class="container global-list-main-container">
    <div class="upper-global-title">
      <h3 class="form-division" id="welcome-container">
        Bienvenido {{ userInformation.name }}
      </h3>

      <div class="special-margin">
        <p v-if="companInformation.STATUS === 'AC'" class="navbar-message">
          Compañia activa <strong class="navbar-active">●</strong>
        </p>

        <p v-if="companInformation.STATUS === 'PE'" class="navbar-message">
          Compañia desactivada (Revise el método de pago)
          <strong class="navbar-desactive">●</strong>
        </p>

        <p class="navbar-message" v-if="nextPaymetnDate">
          Próxima fecha de pago {{ nextPaymetnDate }} ({{
            moment(nextPaymetnDate).diff(moment(), "days")
          }}
          días)
        </p>
      </div>
    </div>
    <div class="upper-global-hint" id="hint-container">
      <b-icon icon="lightbulb-outline" type="is-primary"> </b-icon>
      <span class="global-form-hint-color"> Recuerda: </span> {{ hint }}
    </div>

    <b-field grouped group-multiline class="global-lists-controls"> </b-field>

    <div class="initial-dasboard-main-container">
      <div
        class="initial-dashboard-title-container initial-dashboard-title-container-big-margin"
      >
        <h3 class="form-division">Notificaciones</h3>
        <div
          class="global-border-radius global-border-shadow initial-dashoard-notifications-container"
        >
          <div
            v-for="notification in notifications.reverse()"
            :key="notification._id"
            class="initial-dashboard-single-notification"
          >
            <b-icon
              class="media-left single-notification-status-not-read"
              v-if="notification.status !== 'C'"
            ></b-icon>
            <b-icon
              class="media-left single-notification-status-read"
              v-if="notification.status === 'C'"
            ></b-icon>
            <div class="media-content">
              <h3 class="single-notification-title">
                {{ notification.title }}
              </h3>
              <small>{{ notification.description }}</small>
              <br />
              <small>{{ notification.formattedDate }}</small>
              <br />
              <small
                class="single-notification-mark-read"
                @click="markNotificationAsRead(notification)"
                v-if="notification.status !== 'C'"
                >Marcar como leida</small
              >
            </div>
          </div>
        </div>
      </div>

      <div class="initial-dashboard-title-container">
        <h3 class="form-division">
          Meta de ventas:
          {{
            currentEmployee && currentEmployee.META_VENTAS
              ? currentEmployee.META_VENTAS
              : 0 | money("MXN", 2)
          }}
        </h3>

        <div
          class="global-lists-filters-container special-margin-top"
          id="filter-container"
        >
          <b-field
            label="Fecha de inicio"
            label-position="inside"
            class="no-margin"
            :type="{ 'is-danger': errors.startDate }"
            :message="{
              'La fecha de inicio no es valida': errors.startDate,
            }"
          >
            <b-datepicker
              class="global-lists-filters-control"
              @input="(value) => changeDateFilterValue()"
              :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
              :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
              :month-names="[
                'Enero',
                'Febrero',
                'Marzo',
                'Abril',
                'Mayo',
                'Junio',
                'Julio',
                'Agosto',
                'Septiembre',
                'Octubre',
                'Noviembre',
                'Diciembre',
              ]"
              :show-week-number="true"
              locale="es-MX"
              placeholder="Fecha de inicio"
              icon="calendar-today"
              v-model="startDate"
              trap-focus
            >
            </b-datepicker>
          </b-field>
          <b-field
            label="Fecha de fin"
            label-position="inside"
            class="no-margin"
            :type="{ 'is-danger': errors.endDate }"
            :message="{
              'La fecha de fin no es valida': errors.endDate,
            }"
          >
            <b-datepicker
              class="global-lists-filters-control"
              @input="(value) => changeDateFilterValue()"
              :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
              :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
              :month-names="[
                'Enero',
                'Febrero',
                'Marzo',
                'Abril',
                'Mayo',
                'Junio',
                'Julio',
                'Agosto',
                'Septiembre',
                'Octubre',
                'Noviembre',
                'Diciembre',
              ]"
              :show-week-number="true"
              locale="es-MX"
              placeholder="Fecha de fin"
              icon="calendar-today"
              v-model="endDate"
              trap-focus
            >
            </b-datepicker>
          </b-field>
        </div>

        <b-progress
          class="special-margin-top"
          :rounded="false"
          type="is-success"
          size="is-medium"
          :value="
            salesAnalyticInformation[0] ? salesAnalyticInformation[0].net : 0
          "
          :max="
            currentEmployee && currentEmployee.META_VENTAS
              ? currentEmployee.META_VENTAS
              : 0
          "
          show-value
          format="raw"
          :precision="2"
          :keep-trailing-zeroes="true"
          locale="es-MX"
        >
          {{
            salesAnalyticInformation[0]
              ? salesAnalyticInformation[0].net
              : 0 | money("MXN", 2)
          }}
        </b-progress>

        <div class="initial-single-list-full">
          <b-table
            :data="salesAnalyticInformation"
            pagination-position="both"
            :striped="true"
            :bordered="true"
            :hoverable="true"
            paginated
            :current-page="currentPageSales"
            :per-page="perPageSales"
            default-sort="name"
            default-sort-direction="desc"
            scrollable
          >
            <template #empty>
              <div class="has-text-centered">No hay infomación</div>
            </template>

            <template slot-scope="props">
              <b-table-column
                v-for="column in salesColumns"
                v-bind="column"
                :key="column.field"
                :label="column.label"
                :sortable="column.sortable"
                :searchable="column.searchable"
                :custom-sort="column.customSort"
              >
                <span v-if="!checkIfValid(props.row[column.field])">{{
                  "Sin información"
                }}</span>
                <span
                  v-if="column.money && checkIfValid(props.row[column.field])"
                  >{{ props.row[column.field] | money("MXN", 2) }}</span
                >
                <span
                  v-if="!column.money && checkIfValid(props.row[column.field])"
                  >{{ props.row[column.field] }}</span
                >
              </b-table-column>
            </template>
          </b-table>
        </div>
      </div>

      <div class="initial-dashboard-title-container">
        <h3 class="form-division">Conversiones</h3>
        <div class="global-lists-filters-columns left-list-filters">
          <b-checkbox
            v-for="singleConversion in conversions"
            v-bind:key="singleConversion.label"
            v-model="singleConversion.display"
          >
            {{ singleConversion.label }}
          </b-checkbox>
        </div>
        <UnitConversion v-if="conversions[0].display" />
        <ExchangeRate v-if="conversions[1].display" />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ExchangeRate from "./ExchangeRate";
import UnitConversion from "./UnitConversion";
import SecureLS from "secure-ls";
// @ is an alias to /src
export default {
  name: "InitialDashboard",
  components: {
    ExchangeRate,
    UnitConversion,
  },
  data() {
    return {
      moment: moment,
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 15
      ),
      endDate: new Date(),
      errors: {
        startDate: false,
        endDate: false,
      },
      filterClient: "",
      filterWarehouse: "",
      filterCategory: "",
      filterSubCategory: "",
      conversions: [
        {
          label: "Conversion de unidades",
          display: true,
        },
        {
          label: "Conversion de divisas",
          display: true,
        },
      ],
      salesAnalyticInformation: [],
      salesColumns: [
        {
          field: "total",
          label: "Ventas neto",
          numeric: true,
          money: true,
          sortable: true,
          searchable: false,
        },
        {
          field: "totalCancellations",
          label: "Cancelaciones neto",
          numeric: true,
          money: true,
          sortable: true,
          searchable: false,
        },
        {
          field: "totalClarification",
          label: "Clarificaciones neto",
          numeric: true,
          money: true,
          sortable: true,
          searchable: false,
        },
        {
          field: "totalCreditNotes",
          label: "Notas de crédito neto",
          numeric: true,
          money: true,
          sortable: true,
          searchable: false,
        },
        {
          field: "net",
          label: "Total final neto",
          numeric: true,
          money: true,
          sortable: true,
          searchable: false,
        },
      ],
      perPageSales: 50,
      currentPageSales: 1,
    };
  },
  created() {
    moment.locale("es");
  },
  async mounted() {
    this.$store.dispatch("CHANGEACTIVE", "home");
    // Show beta message if user has not accepted beta message
    if (!this.userInformation.betaMessageCompleted) {
      this.$buefy.dialog.confirm({
        title: "Bienvenido a Coconut Control Beta",
        message:
          "Coconut Control agradece su preferencia. Por el momento nos encontrmos en beta por lo que no nos responsabilizamos por el mal uso que los usuarios puedan darle a la plataforma.",
        confirmText: "Entendido",
        type: "is-success",
        hasIcon: true,
        onConfirm: async () => {
          await this.finishBetaMesage();
          this.$tours["initialTour"].start();
        },
        cancelText: "cancelar",
        canCancel: false,
      });
    }
    // Only start the tour if the user has not finish it
    if (
      !this.userInformation.tourCompleted &&
      this.userInformation.betaMessageCompleted
    ) {
      this.$tours["initialTour"].start();
    }

    await this.getCurrentPersonal();
    await this.getAllSalesInformation();
  },
  methods: {
    async markNotificationAsRead(notification) {
      try {
        let response = await this.$store.dispatch("MARKNOTIFICATIONASREAD", {
          notificationId: notification._id,
        });
        if (response !== "Success") {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al marcar la notificación como leida en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al marcar la notificación como leida en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    async markAllNotificationAsRead() {
      try {
        let response = await this.$store.dispatch(
          "MARKALLNOTIFICATIONASREAD",
          {}
        );
        if (response !== "Success") {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al marcar las notificaciones como leidas en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al marcar las notificaciones como leida en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    async finishBetaMesage() {
      const response = await this.$store.dispatch("COMPLETEBETAMESSAGE", {});
      if (response === "Success") {
        const ls = new SecureLS({ isCompression: false });
        const currentUserInformation = JSON.parse(
          ls.get(this.userInformation.companyMainName + "ERPFCBUser")
        );
        ls.set(
          this.userInformation.companyMainName + "ERPFCBUser",
          JSON.stringify({
            ...currentUserInformation,
            betaMessageCompleted: true,
          })
        );
      }
    },
    async getAllSalesInformation() {
      try {
        if (this.currentEmployee) {
          this.salesAnalyticInformation = [];

          let response = await this.$store.dispatch("GETCOMPANYSALESDATE", {
            startDate: this.startDate,
            endDate: new Date(
              new Date(this.endDate).getFullYear(),
              new Date(this.endDate).getMonth(),
              new Date(this.endDate).getDate() + 1
            ),
            warehouse: this.filterWarehouse,
            client: this.filterClient,
            category: this.filterCategory,
            subCategory: this.filterSubCategory,
            seller: this.currentEmployee._id,
          });

          if (response) {
            const netWarehousesSale =
              response.total -
              response.totalCancellations -
              response.totalClarification -
              response.totalCreditNotes;

            this.salesAnalyticInformation.push({
              net: netWarehousesSale,
              ...response,
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getCurrentPersonal() {
      try {
        if (this.user) {
          let response = await this.$store.dispatch("GETCURRENTPERSONAL", {
            userId: this.user._id,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async changeDateFilterValue() {
      if (moment(new Date(this.startDate)).isAfter(new Date(this.endDate))) {
        this.errors.starDate = true;
      } else {
        this.errors.starDate = false;
      }

      if (moment(new Date(this.endDate)).isBefore(new Date(this.starDate))) {
        this.errors.endDate = true;
      } else {
        this.errors.endDate = false;
      }

      if (!this.errors.startDate && !this.errors.endDate) {
        const promiseArray = [];

        promiseArray.push(this.getAllSalesInformation());

        await Promise.all(promiseArray);
      }
    },
  },
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
    userInformation() {
      return this.$store.getters.USER;
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
    nextPaymetnDate() {
      return this.$store.getters.NEXTPAYMENTDATE;
    },
    companInformation() {
      return this.$store.getters.COMPANY_INFORMATION;
    },
    notifications() {
      return this.$store.getters.NOTIFICATIONS.map((singleNotification) => {
        const singleNotificationWithProps = { ...singleNotification };

        singleNotificationWithProps.formattedDate = moment(
          singleNotification.date
        ).format("DD-MM-YYYY HH:mm");

        return singleNotificationWithProps;
      });
    },
    user() {
      return this.$store.getters.USER;
    },
    users() {
      return this.$store.getters.USERS;
    },
    currentEmployee() {
      return this.$store.getters.CURRENTEMPLOYEE;
    },
  },
  watch: {
    async user() {
      await this.getCurrentPersonal();
      await this.getAllSalesInformation();
    },
  },
};
</script>

<style scoped>
@import "../../../Bank/style/GeneralResumeeBank.css";
@import "../../../Global/style/Global.css";
@import "../../../Global/style/InitialDashboard.css";
</style>
